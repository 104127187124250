.report-container {
    padding: 16px;
}

.monthYear {
    margin-bottom: 10px;
}
.ant-table {
    overflow-x: auto;
    margin-top: 50px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    white-space: nowrap;
    padding: 8px;
}

@media (max-width: 600px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        font-size: 12px;
    }
}
