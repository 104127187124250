.tableitem .itemtext {
    word-wrap: break-word; 
    max-width: 70px;
}

.table {
    width: 100%;
    table-layout: fixed;
    background-color: white;
}
#bot {
    align-items: center;
    max-width: "80%";
    margin-left: -10px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  font-size: 14px !important;
}
#invoice-POS table td,
#invoice-POS table th {
  padding: 4px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}


@media (max-width: 600px) {
    .ant-table {
      font-size: 12px; 
    }
    .ant-table-column-title {
      padding: 2px; 
    }
    .ant-table-cell {
      padding: 8px;
    }
  }
#invoice-POS table {
  font-size: 10px;
}

#invoice-POS .table-header p {
  font-size: 11px;
}

#invoice-POS .itemtext {
  font-size: 10px;
}

#invoice-POS .tax-label,
#invoice-POS .tax-value {
  font-size: 10px;
}

#invoice-POS .grand-total-label,
#invoice-POS .grand-total-value {
  font-size: 10px;
}

#legalcopy .legal{
  font-size: 10px;
}